<template>
  <router-view/>
</template>

<style>

@font-face {
    font-family: "CraftworkGrotesk";
    font-weight: 800;
    src: url('/public/craftwork-grotesk/CraftworkGrotesk-Heavy.ttf') format('truetype');
  }
  
  @font-face {
    font-family: "CraftworkGrotesk";
    font-weight: 700;
    src: url('/public/craftwork-grotesk/CraftworkGrotesk-Bold.ttf') format('truetype');
  }
  
  @font-face {
    font-family: "CraftworkGrotesk";
    font-weight: 600;
    src: url('/public/craftwork-grotesk/CraftworkGrotesk-SemiBold.ttf') format('truetype');
  }
  
  @font-face {
    font-family: "CraftworkGrotesk";
    font-weight: 500;
    src: url('/public/craftwork-grotesk/CraftworkGrotesk-Medium.ttf') format('truetype');
  }
  
  @font-face {
    font-family: "CraftworkGrotesk";
    font-weight: 400;
    src: url('/public/craftwork-grotesk/CraftworkGrotesk-Regular.ttf') format('truetype');
  }
  

#app {
  font-family: 'CraftworkGrotesk';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

*{
  font-family: 'CraftworkGrotesk';
}

h1, h2, h3, h4, h5, h6, p, a{
  margin: 0;
  padding: 0;
}
</style>
